import React from "react";
import {
  SEO,
  MarkdownContent,
  Image,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class CareersPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "02b31634-9639-48b8-9539-5f8e2def792c",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={""}
            style={{ padding: 32 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "d97a54c7-45c4-4118-ad6f-a52830b31755",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={""} style={{ padding: 16 }} width={6}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "89c2ffc7-3ad5-4b1b-a8d1-c8b8890254d0",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={""}
            style={{ padding: 32 }}
            verticalAlign={"middle"}
            columns={2}
          >
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "991d38e7-d41f-4b02-b24d-834253929614",
                    numToSelect: 1,
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={""} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "32a5924b-d74b-4eb1-982b-f9dc08b5550d",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} columns={0} />
          <Grid.Row className={""} style={{ padding: 0 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  businessId={1159}
                  businessType={"merchant"}
                  locationId={1223}
                  subheader={"Join Us"}
                  showLabels={true}
                  emailSubject={"Join Us"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Name",
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Email",
                    },
                    {
                      type: "input",
                      label: "phone",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Phone",
                    },
                    {
                      type: "input",
                      label: "instagram",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Instagram",
                    },
                    {
                      type: "select",
                      label:
                        "do_you_have_a_cosmetology_or_esthetician_license_in_washington_state",
                      options: [
                        {
                          label: "Cosmetology license",
                          value: "Cosmetology license",
                        },
                        {
                          label: "Esthetician license",
                          value: "Esthetician license",
                        },
                        {
                          label:
                            "I do not have either license in Washington State",
                          value:
                            "I do not have either license in Washington State",
                        },
                      ],
                      required: true,
                      inputType: "select",
                      placeholder: "",
                      displayLabel:
                        "Do you have a cosmetology or esthetician license in Washington State?",
                    },
                    {
                      type: "textarea",
                      label: "what_are_your_goals_for_your_future_career",
                      required: true,
                      inputType: "textarea",
                      placeholder: "",
                      displayLabel:
                        "What are your goals for your future career?",
                    },
                    {
                      type: "textarea",
                      label: "what_expectations_do_you_have",
                      required: true,
                      inputType: "textarea",
                      placeholder: "",
                      displayLabel: "What expectations do you have?",
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 22035 }) {
      title
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
